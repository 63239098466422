<template>
    <v-row no-gutters dense>
        <v-col cols="12" class="my-card">
            <div class="card-header mb-8">
                <v-row no-gutters dense>
                    <v-col cols="6">
                        <p class="heading-sf20">Daily List & Bid Created</p>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center">
                        <div v-click-outside="outside">
                            <div class="d-flex justify-end">
                                <div class="ml-auto heading-rf15">Filter by month & year</div>
                                <div v-on:click="filter_data.is_show = !filter_data.is_show" class="ml-4 my-pointer d-flex flex-column">
                                    <img :src="$store.state.icons.icons['calendar']" style="height: 20px" />
                                </div>
                            </div>

                            <div v-if="filter_data.is_show" class="pt-2">
                                <app-date-time v-model="filter_data"></app-date-time>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div>
                <canvas ref="myChart" style="height: 300px" class="col-12"></canvas>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import Chart from "../../plugins/chart";
    import { filterFormat } from "../../services/commonservice";
    export default {
        components: {
            AppDateTime: () => import("../../mycomponents/DateTime"),
        },

        directives: {
            "click-outside": {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== "function") {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                        if (compName) {
                            warn += `Found in component '${compName}'`;
                        }

                        console.warn(warn);
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e);
                        }
                    };
                    el.__vueClickOutside__ = handler;

                    // add Event Listeners
                    document.addEventListener("click", handler);
                },

                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener("click", el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                },
            },
        },

        data() {
            return {
                myChart: this.$store.state.barchart.myChart1,
                filter_data: {
                    is_show: false,
                    filter_type: this.$store.state.barchart.list_bid_data.filter_type,
                    old_filter_type: this.$store.state.barchart.list_bid_data.filter_type,
                    filter_date: this.$store.state.barchart.list_bid_data.date,
                    old_filter_date: this.$store.state.barchart.list_bid_data.date,
                },

                datacollection: {
                    labels: this.labels,
                    datasets: [
                        {
                            label: "Lists",
                            backgroundColor: "#203765",
                            pointBackgroundColor: "white",
                            borderWidth: 1,
                            pointBorderColor: "#203765",
                            data: this.lists,
                        },
                        {
                            label: "Bids",
                            backgroundColor: "#3066CB",
                            pointBackgroundColor: "white",
                            borderWidth: 1,
                            pointBorderColor: "#3066CB",
                            data: this.bids,
                        },
                        {
                            label: "Accepted Bids",
                            backgroundColor: "#66C488",
                            pointBackgroundColor: "white",
                            borderWidth: 1,
                            pointBorderColor: "#66C488",
                            data: this.acceptedBids,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                                gridLines: {
                                    display: true,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                                gridLines: {
                                    display: false,
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                    },
                    tooltips: {
                        enabled: true,
                        mode: "single",
                        callbacks: {
                            label: function (tooltipItems, data) {
                                return tooltipItems.yLabel;
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    height: 300,
                },
            };
        },

        watch: {
            "filter_data.is_show"() {
                if ((this.filter_data.is_show === false && this.filter_data.old_filter_date !== this.filter_data.filter_date) || this.filter_data.old_filter_type !== this.filter_data.filter_type) {
                    this.filter_data.old_filter_type = this.filter_data.filter_type;
                    this.filter_data.old_filter_date = this.filter_data.filter_date;

                    this.$store.dispatch("listbidChart", { date: this.filter_data.filter_date, type: this.filter_data.filter_type });
                }
            },
        },

        created() {
            this.$store.watch(
                (state) => {
                    return state.barchart.list_bid_data;
                },
                () => {
                    this.createChart();
                },
                { deep: true }
            );
        },

        mounted() {
            if (this.$store.getters.getListBidLables.length > 5) {
                this.createChart();
            } else {
                this.$store.dispatch("listbidChart", { date: this.filter_data.filter_date, type: this.filter_data.filter_type });
                this.createChart();
            }
        },

        computed: {
            labels() {
                return this.$store.getters.getListBidLables;
            },

            lists() {
                return this.$store.getters.getListBidLists;
            },

            bids() {
                return this.$store.getters.getListBidBids;
            },

            acceptedBids() {
                return this.$store.getters.getListBidAcceptedBids;
            },

            DC() {
                return this.datacollection;
            },

            OD() {
                return this.options;
            },
        },

        methods: {
            createChart() {
                this.datacollection.labels = this.labels;
                this.datacollection.datasets[0].data = this.lists;
                this.datacollection.datasets[1].data = this.bids;
                this.datacollection.datasets[2].data = this.acceptedBids;

                if (this.myChart !== null) {
                    this.myChart.destroy();
                }

                var ctx = this.$refs.myChart;
                if (ctx !== undefined) {
                    this.myChart = new Chart(ctx, {
                        type: "bar",
                        data: this.DC,
                        options: this.OD,
                    });
                }

                this.$store.state.barchart.myChart1 = this.myChart;
            },

            outside(e) {
                if (this.filter_data.is_show) {
                    this.filter_data.is_show = false;
                }
            },
        },
    };
</script>

<style scoped></style>
